import axios from 'axios';
let url = '';
if (process.env.NODE_ENV === 'development') {
	// url = 'http://127.0.0.1:8000/api/';
	url = 'https://back.printster.in/api/';
} else {
	url = 'https://back.printster.in/api/';
}
export default axios.create({
	baseURL: url
});
