const Menu = [
	{
		heading: 'Main Navigation',
		translate: 'sidebar.heading.HEADER',
		role: 'admin'
	},
	{
		name: 'Dashboard',
		icon: 'icon-speedometer',
		translate: 'sidebar.nav.DASHBOARD',
		role: 'admin',
		path: '/dashboardv1'
		// submenu: [
		// 	{
		// 		name: 'Dashboard',
		// 		path: '/dashboardv1'
		// 	}
		// ]
	},
	{
		name: 'Dashboard',
		icon: 'icon-speedometer',
		translate: 'sidebar.nav.DASHBOARD',
		role: 'vendor',
		path: '/dashboardv1'
	},
	{
		name: 'User',
		icon: 'icon-user',
		translate: 'sidebar.nav.UserList',
		role: 'admin',
		submenu: [
			{
				name: 'User List',
				path: '/users'
			},
			{
				name: 'Vendor List',
				path: '/vendors'
			}
		]
	},
	{
		name: 'Category',
		icon: 'icon-list',
		translate: 'sidebar.nav.Category',
		role: 'admin',
		submenu: [
			{
				name: 'Category',
				path: '/category'
			}
		]
	},
	{
		name: 'Product',
		icon: 'icon-bag',
		translate: 'sidebar.nav.Product',
		role: 'admin',
		submenu: [
			{
				name: 'Pricing List',
				path: '/pricing'
			},
			{
				name: 'Attribute List',
				path: '/attribute'
			},
			{
				name: 'Product List',
				path: '/product'
			},
			{
				name: 'Discount List',
				path: '/discount'
			}
		]
	},

	{
		name: 'Orders',
		icon: 'icon-basket-loaded',
		translate: 'sidebar.nav.ORDERS',
		role: 'admin',
		submenu: [
			{
				name: 'Order All',
				path: '/order-all'
			},
			{
				name: 'Pending Order',
				path: '/order-pending'
			},
			{
				name: 'Printed Order',
				path: '/order-printing-done'
			},
			{
				name: 'Change Vendor',
				path: '/change-vendor'
			},
			{
				name: 'Packed Order',
				path: '/order-packing-done'
			},
			{
				name: 'Completed All',
				path: '/order-completed'
			}
		]
	},
	{
		name: 'Orders Refund',
		icon: 'icon-basket-loaded',
		translate: 'Orders Refund',
		role: 'admin',
		submenu: [
			{
				name: 'Refund Order',
				path: '/order-return'
			},
			{
				name: 'Custome Orders',
				path: '/custome-order'
			},
			{
				name: 'User Cart',
				path: '/user-cart-order'
			},

			{
				name: 'UnPaid Orders',
				path: 'unpaid-order'
			},
			{
				name: 'Orders Reviews',
				path: '/reviews'
			}
		]
	},

	{
		name: 'Forms',
		icon: 'icon-note',
		translate: 'sidebar.nav.form.FORM',
		role: 'admin',
		submenu: [
			{
				name: 'Contact Request',
				path: '/contact'
			}
		]
	},
	{
		name: 'Setting',
		icon: 'icon-doc',
		translate: 'sidebar.nav.Setting',
		role: 'admin',
		submenu: [
			{
				name: 'Setting',
				path: '/setting'
			},
			{ name: 'User Cart Clear', path: '/user-cart-clear' },
			{
				name: 'DTDC Code',
				path: '/dtdccode'
			},
			{
				name: 'Faq',
				path: '/faq'
			},
			{
				name: 'Career',
				path: '/career'
			},
			{
				name: 'Pages',
				path: '/pages'
			},
			{
				name: 'Pages Media',
				path: '/pagesmedia'
			},
			{
				name: 'Site Message',
				path: '/site-message'
			}
		]
	},

	{
		name: 'Orders',
		icon: 'icon-basket-loaded',
		translate: 'sidebar.nav.ORDERS',
		role: 'vendor',
		submenu: [
			{
				name: 'All Orders',
				path: '/order-vendor-all'
			},
			{
				name: 'Pending Orders',
				path: '/order-vendor-pending'
			},
			{
				name: 'Printed Orders',
				path: '/order-vendor-printted'
			},
			{
				name: 'Completed Orders',
				path: '/order-vendor-completed'
			}
		]
	}
];

export default Menu;
