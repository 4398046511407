import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const DashboardV1 = lazy(() => import('./components/Dashboard/DashboardV1'));
const DashboardV2 = lazy(() => import('./components/Dashboard/DashboardV2'));
const DashboardV3 = lazy(() => import('./components/Dashboard/DashboardV3'));

const Widgets = lazy(() => import('./components/Widgets/Widgets'));

const Buttons = lazy(() => import('./components/Elements/Buttons'));
const Notifications = lazy(() => import('./components/Elements/Notifications'));
const SweetAlert = lazy(() => import('./components/Elements/SweetAlert'));
const BsCarousel = lazy(() => import('./components/Elements/Carousel'));
const Spinner = lazy(() => import('./components/Elements/Spinner'));
const DropdownAnimation = lazy(() => import('./components/Elements/DropdownAnimation'));
const Nestable = lazy(() => import('./components/Elements/Nestable'));
const Sortable = lazy(() => import('./components/Elements/Sortable'));
const Cards = lazy(() => import('./components/Elements/Cards'));
const Grid = lazy(() => import('./components/Elements/Grid'));
const GridMasonry = lazy(() => import('./components/Elements/GridMasonry'));
const Typography = lazy(() => import('./components/Elements/Typography'));
const FontIcons = lazy(() => import('./components/Elements/FontIcons'));
const WeatherIcons = lazy(() => import('./components/Elements/WeatherIcons'));
const Colors = lazy(() => import('./components/Elements/Colors'));

const ChartFlot = lazy(() => import('./components/Charts/ChartFlot'));
const ChartRadial = lazy(() => import('./components/Charts/ChartRadial'));
const ChartChartJS = lazy(() => import('./components/Charts/ChartChartJS'));
const ChartMorris = lazy(() => import('./components/Charts/ChartMorris'));
const ChartChartist = lazy(() => import('./components/Charts/ChartChartist'));

const MapsGoogle = lazy(() => import('./components/Maps/MapsGoogle'));
const MapsVector = lazy(() => import('./components/Maps/MapsVector'));

const TableStandard = lazy(() => import('./components/Tables/TableStandard'));
const TableExtended = lazy(() => import('./components/Tables/TableExtended'));
const Datatable = lazy(() => import('./components/Tables/DatatableView'));
const DataGrid = lazy(() => import('./components/Tables/DataGrid'));

const FormStandard = lazy(() => import('./components/Forms/FormStandard'));
const FormExtended = lazy(() => import('./components/Forms/FormExtended'));
const FormValidation = lazy(() => import('./components/Forms/FormValidation'));
const FormWizard = lazy(() => import('./components/Forms/FormWizard'));
const FormUpload = lazy(() => import('./components/Forms/FormUpload'));
const FormCropper = lazy(() => import('./components/Forms/FormCropper'));

const Login = lazy(() => import('./components/Pages/Login'));
const Register = lazy(() => import('./components/Pages/Register'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));

const Mailbox = lazy(() => import('./components/Extras/Mailbox'));
const Timeline = lazy(() => import('./components/Extras/Timeline'));
const Calendar = lazy(() => import('./components/Extras/Calendar'));
const Invoice = lazy(() => import('./components/Extras/Invoice'));
const Search = lazy(() => import('./components/Extras/Search'));
const Todo = lazy(() => import('./components/Extras/Todo'));
const Profile = lazy(() => import('./components/Extras/Profile'));
const BugTracker = lazy(() => import('./components/Extras/BugTracker'));
// const ContactDetails = lazy(() => import('./components/Extras/ContactDetails'));
const Contacts = lazy(() => import('./components/Extras/Contacts'));
const Faq = lazy(() => import('./components/Extras/Faq'));
const FileManager = lazy(() => import('./components/Extras/FileManager'));
const Followers = lazy(() => import('./components/Extras/Followers'));
const HelpCenter = lazy(() => import('./components/Extras/HelpCenter'));
const Plans = lazy(() => import('./components/Extras/Plans'));
const ProjectDetails = lazy(() => import('./components/Extras/ProjectDetails'));
const Projects = lazy(() => import('./components/Extras/Projects'));
const Settings = lazy(() => import('./components/Extras/Settings'));
const SocialBoard = lazy(() => import('./components/Extras/SocialBoard'));
const TeamViewer = lazy(() => import('./components/Extras/TeamViewer'));
const VoteLinks = lazy(() => import('./components/Extras/VoteLinks'));

const EcommerceOrder = lazy(() => import('./components/Ecommerce/EcommerceOrders'));
const EcommerceOrderView = lazy(() => import('./components/Ecommerce/EcommerceOrderView'));
const EcommerceProduct = lazy(() => import('./components/Ecommerce/EcommerceProducts'));
const EcommerceProductView = lazy(() => import('./components/Ecommerce/EcommerceProductView'));
const EcommerceCheckout = lazy(() => import('./components/Ecommerce/EcommerceCheckout'));

const BlogList = lazy(() => import('./components/Blog/BlogList'));
const BlogPost = lazy(() => import('./components/Blog/BlogPost'));
const BlogArticle = lazy(() => import('./components/Blog/BlogArticles'));
const BlogArticleView = lazy(() => import('./components/Blog/BlogArticleView'));

const ForumHome = lazy(() => import('./components/Forum/ForumHome'));

const UserList = lazy(() => import('./pages/admin/userlist'));
const VendorList = lazy(() => import('./pages/admin/vendorlist'));
const UserDetails = lazy(() => import('./pages/admin/userdetails'));
const VendorDetails = lazy(() => import('./pages/admin/vendordetails'));
const UserDetailsEdit = lazy(() => import('./pages/admin/userdetailsedit'));
const VendorDetailsEdit = lazy(() => import('./pages/admin/vendordetailsedit'));
const CategoryList = lazy(() => import('./pages/admin/categorylist'));
const CategoryDetails = lazy(() => import('./pages/admin/categorydetails'));
const CategoryDetailsEdit = lazy(() => import('./pages/admin/categorydetailsedit'));
const AttributeList = lazy(() => import('./pages/admin/attributelist'));
const ProductList = lazy(() => import('./pages/admin/productlist'));
const AttributeDetails = lazy(() => import('./pages/admin/attributedetails'));
const AttributeDetailsEdit = lazy(() => import('./pages/admin/attributedetailsedit'));
const ProductDetails = lazy(() => import('./pages/admin/productdetails'));
const ProductDetailsEdit = lazy(() => import('./pages/admin/productdetailsedit'));
const PricingList = lazy(() => import('./pages/admin/pricinglist'));
const PricingDetails = lazy(() => import('./pages/admin/pricingdetails'));
const PricingDetailsEdit = lazy(() => import('./pages/admin/pricingdetailsedit'));
const DiscountList = lazy(() => import('./pages/admin/discountlist'));
const DiscountDetails = lazy(() => import('./pages/admin/discountdetails'));
const DiscountDetailsEdit = lazy(() => import('./pages/admin/discounteditdetails'));
const OrderAll = lazy(() => import('./pages/admin/orderall'));
const OrderDetails = lazy(() => import('./pages/admin/orderdetails'));
const UnPaidOrderDetails = lazy(() => import('./pages/admin/unpaidorderdetails'));
const Setting = lazy(() => import('./pages/admin/setting'));
const OrderVendorAll = lazy(() => import('./pages/admin/ordervendorall'));
const OrderVendorDetails = lazy(() => import('./pages/admin/ordervendordetails'));
const OrderReturn = lazy(() => import('./pages/admin/orderreturn'));
const OrderVendorPending = lazy(() => import('./pages/admin/ordervendorpending'));
const OrderChangeVendor = lazy(() => import('./pages/admin/orderchangevendor'));
const OrderVendorCompleted = lazy(() => import('./pages/admin/ordervendorcompleted'));
const OrderVendorPrinted = lazy(() => import('./pages/admin/ordervendorprinted'));
const OrderPending = lazy(() => import('./pages/admin/orderpending'));
const OrderPrintingDone = lazy(() => import('./pages/admin/orderprintingdone'));
const OrderPackingDone = lazy(() => import('./pages/admin/orderpackingdone'));
const OrderCompleted = lazy(() => import('./pages/admin/ordercompleted'));
const FaqList = lazy(() => import('./pages/admin/faqlist'));
const FaqDetails = lazy(() => import('./pages/admin/faqdetails'));
const FaqDetailsEdit = lazy(() => import('./pages/admin/faqdetailsedit'));
const CareerList = lazy(() => import('./pages/admin/careerlist'));
const CareerDetails = lazy(() => import('./pages/admin/careerdetails'));
const CareerDetailsEdit = lazy(() => import('./pages/admin/careerdetailsedit'));
const PagesDetails = lazy(() => import('./pages/admin/pagesdetails'));
const PagesDetailsEdit = lazy(() => import('./pages/admin/pagesdetailsedit'));
const PagesList = lazy(() => import('./pages/admin/pageslist'));
const PagesMediaList = lazy(() => import('./pages/admin/pagesmedialist'));
const PagesMediaDetails = lazy(() => import('./pages/admin/pagesmediadetails'));
const PagesMediaDetailsEdit = lazy(() => import('./pages/admin/pagesmediadetailsedit'));
const ReviewList = lazy(() => import('./pages/admin/reviewlist'));
const ContactList = lazy(() => import('./pages/admin/contactlist'));
const ContactDetails = lazy(() => import('./pages/admin/contactdetails'));
const CustomeOrderAll = lazy(() => import('./pages/admin/ordercustome'));
const UnPaidOrder = lazy(() => import('./pages/admin/unpaidorder'));
const OrderChat = lazy(() => import('./pages/admin/orderchat'));
const UserPrintCashHsitory = lazy(() => import('./pages/admin/userprintcashhistory'));
const UserNotification = lazy(() => import('./pages/admin/usernotification'));
const UserAddress = lazy(() => import('./pages/admin/useraddress'));
const UserOrderHsitory = lazy(() => import('./pages/admin/userorderhistory'));
const UserCartHsitory = lazy(() => import('./pages/admin/usercarthistory'));
const SiteMessageList = lazy(() => import('./pages/admin/sitemessagelist'));
const SiteMessageDetails = lazy(() => import('./pages/admin/sitemessagedetails'));
const SiteMessageDetailsEdit = lazy(() => import('./pages/admin/sitemessagedetailsedit'));
const ChangePassword = lazy(() => import('./pages/admin/changepassword'));
const DtdcCode = lazy(() => import('./pages/admin/dtdccode'));
const UserCart = lazy(() => import('./pages/admin/usercart'));
const UserCartClear = lazy(() => import('./pages/admin/usercartclear'));
// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = ['/login', '/register', '/recover', '/lock', '/notfound', '/error500', '/maintenance'];

const Routes = ({ location }) => {
	const currentKey = location.pathname.split('/')[1] || '/';
	const timeout = { enter: 500, exit: 500 };

	// Animations supported
	//      'rag-fadeIn'
	//      'rag-fadeInRight'
	//      'rag-fadeInLeft'

	const animationName = 'rag-fadeIn';

	if (listofPages.indexOf(location.pathname) > -1) {
		return (
			// Page Layout component wrapper
			<BasePage>
				<Suspense fallback={<PageLoader />}>
					<Switch location={location}>
						<Route path="/login" component={waitFor(Login)} />
						<Route path="/register" component={waitFor(Register)} />
						<Route path="/recover" component={waitFor(Recover)} />
						<Route path="/lock" component={waitFor(Lock)} />
						<Route path="/notfound" component={waitFor(NotFound)} />
						<Route path="/error500" component={waitFor(Error500)} />
						<Route path="/maintenance" component={waitFor(Maintenance)} />
					</Switch>
				</Suspense>
			</BasePage>
		);
	} else {
		return (
			// Layout component wrapper
			// Use <BaseHorizontal> to change layout
			<Base>
				<TransitionGroup>
					<CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
						<div>
							<Suspense fallback={<PageLoader />}>
								<Switch location={location}>
									{/*Dashboard*/}
									<Route path="/dashboardv1" component={waitFor(DashboardV1)} />
									<Route path="/changepassword" component={waitFor(ChangePassword)} />
									<Route path="/dtdccode" component={waitFor(DtdcCode)} />
									<Route path="/dashboardv2" component={waitFor(DashboardV2)} />
									<Route path="/dashboardv3" component={waitFor(DashboardV3)} />
									{/*Widgets*/}
									<Route path="/widgets" component={waitFor(Widgets)} />
									{/*User section in admin*/}
									<Route path="/users" component={waitFor(UserList)} />
									<Route path="/user-print-cash-history" component={waitFor(UserPrintCashHsitory)} />
									<Route path="/user-notification" component={waitFor(UserNotification)} />
									<Route path="/user-address" component={waitFor(UserAddress)} />
									<Route path="/user-orders" component={waitFor(UserOrderHsitory)} />
									<Route path="/user-cart" component={waitFor(UserCartHsitory)} />
									<Route path="/user-cart-clear" component={waitFor(UserCartClear)} />
									<Route path="/userdetails" component={waitFor(UserDetails)} />
									<Route path="/user-details-edit" component={waitFor(UserDetailsEdit)} />
									<Route path="/vendors" component={waitFor(VendorList)} />
									<Route path="/vendordetails" component={waitFor(VendorDetails)} />
									<Route path="/vendor-details-edit" component={waitFor(VendorDetailsEdit)} />
									{/*User section in admin end*/}
									{/*Category section in admin*/}
									<Route path="/category-details-edit" component={waitFor(CategoryDetailsEdit)} />
									<Route path="/category-details" component={waitFor(CategoryDetails)} />
									<Route path="/category" component={waitFor(CategoryList)} />
									{/*Category section in admin end*/}
									{/*Attribute section in admin*/}
									<Route path="/attribute-details-edit" component={waitFor(AttributeDetailsEdit)} />
									<Route path="/attribute-details" component={waitFor(AttributeDetails)} />
									<Route path="/attribute" component={waitFor(AttributeList)} />
									{/*Attribute section in admin end*/}
									{/*Product section in admin*/}
									<Route path="/product-details-edit" component={waitFor(ProductDetailsEdit)} />
									<Route path="/product-details" component={waitFor(ProductDetails)} />
									<Route path="/product" component={waitFor(ProductList)} />
									{/*Product section in admin end*/}
									{/*Product section in admin*/}
									<Route path="/pricing-details-edit" component={waitFor(PricingDetailsEdit)} />
									<Route path="/pricing-details" component={waitFor(PricingDetails)} />
									<Route path="/pricing" component={waitFor(PricingList)} />
									{/*Product section in admin end*/}
									{/*Discount section in admin*/}
									<Route path="/discount-details-edit" component={waitFor(DiscountDetailsEdit)} />
									<Route path="/discount-details" component={waitFor(DiscountDetails)} />
									<Route path="/discount" component={waitFor(DiscountList)} />
									{/*Discount section in admin end*/}
									{/*Order section in admin*/}
									<Route path="/order-return/" component={waitFor(OrderReturn)} />
									<Route path="/order-all/" component={waitFor(OrderAll)} />
									<Route path="/order-pending/" component={waitFor(OrderPending)} />
									<Route path="/order-printing-done/" component={waitFor(OrderPrintingDone)} />
									<Route path="/order-packing-done/" component={waitFor(OrderPackingDone)} />
									<Route path="/order-completed/" component={waitFor(OrderCompleted)} />
									<Route path="/change-vendor/" component={waitFor(OrderChangeVendor)} />

									<Route path="/order-details" component={waitFor(OrderDetails)} />
									<Route path="/un-paid-order-details" component={waitFor(UnPaidOrderDetails)} />

									<Route path="/order-details-chat" component={waitFor(OrderChat)} />
									<Route path="/order-vendor-all/" component={waitFor(OrderVendorAll)} />
									<Route path="/order-vendor-pending/" component={waitFor(OrderVendorPending)} />
									<Route path="/order-vendor-printted/" component={waitFor(OrderVendorPrinted)} />
									<Route path="/order-vendor-completed/" component={waitFor(OrderVendorCompleted)} />
									<Route path="/order-vendor-details" component={waitFor(OrderVendorDetails)} />
									<Route path="/order-return/" component={waitFor(OrderReturn)} />
									{/*Order section in admin end*/}
									<Route path="/setting" component={waitFor(Setting)} />
									{/*Faq section in admin*/}
									<Route path="/faq-details-edit" component={waitFor(FaqDetailsEdit)} />
									<Route path="/faq-details" component={waitFor(FaqDetails)} />
									<Route path="/faq" component={waitFor(FaqList)} />
									{/*Faq section in admin end*/}
									{/*Site Message in admin*/}
									<Route path="/user-cart-order" component={waitFor(UserCart)} />

									<Route
										path="/site-message-details-edit"
										component={waitFor(SiteMessageDetailsEdit)}
									/>
									<Route path="/site-message-details" component={waitFor(SiteMessageDetails)} />
									<Route path="/site-message" component={waitFor(SiteMessageList)} />
									{/*Site Message in admin end*/}
									{/*Career section in admin*/}
									<Route path="/career-details-edit" component={waitFor(CareerDetailsEdit)} />
									<Route path="/career-details" component={waitFor(CareerDetails)} />
									<Route path="/career" component={waitFor(CareerList)} />
									{/*Career section in admin end*/}
									{/*Pages section in admin*/}
									<Route path="/pages-details-edit" component={waitFor(PagesDetailsEdit)} />
									<Route path="/pages-details" component={waitFor(PagesDetails)} />
									<Route path="/pages" component={waitFor(PagesList)} />
									{/*Pages section in admin end*/}
									{/*Pages Media section in admin*/}
									<Route path="/pagesmedia-details-edit" component={waitFor(PagesMediaDetailsEdit)} />
									<Route path="/pagesmedia-details" component={waitFor(PagesMediaDetails)} />
									<Route path="/pagesmedia" component={waitFor(PagesMediaList)} />
									{/*Pages Media section in admin end*/}
									{/*Review section in admin*/}
									<Route path="/reviews" component={waitFor(ReviewList)} />
									{/*Review section in admin end*/}
									{/*Contact Section section in admin*/}
									<Route path="/contact" component={waitFor(ContactList)} />
									<Route path="/contact/details/:id" component={waitFor(ContactDetails)} />
									{/*Contact Section section in admin end*/}
									{/*Custome Order section in admin*/}
									<Route path="/custome-order" component={waitFor(CustomeOrderAll)} />
									{/*Custome Order section in admin end*/}
									{/*UnPaid Order section in admin*/}
									<Route path="/unpaid-order" component={waitFor(UnPaidOrder)} />
									{/*UnPaid Order section in admin end*/}
									{/*Elements*/}
									<Route path="/buttons" component={waitFor(Buttons)} />
									<Route path="/notifications" component={waitFor(Notifications)} />
									<Route path="/sweetalert" component={waitFor(SweetAlert)} />
									<Route path="/carousel" component={waitFor(BsCarousel)} />
									<Route path="/spinners" component={waitFor(Spinner)} />
									<Route path="/dropdown" component={waitFor(DropdownAnimation)} />
									<Route path="/nestable" component={waitFor(Nestable)} />
									<Route path="/sortable" component={waitFor(Sortable)} />
									<Route path="/cards" component={waitFor(Cards)} />
									<Route path="/grid" component={waitFor(Grid)} />
									<Route path="/grid-masonry" component={waitFor(GridMasonry)} />
									<Route path="/typography" component={waitFor(Typography)} />
									<Route path="/icons-font" component={waitFor(FontIcons)} />
									<Route path="/icons-weather" component={waitFor(WeatherIcons)} />
									<Route path="/colors" component={waitFor(Colors)} />
									{/*Forms*/}
									<Route path="/form-standard" component={waitFor(FormStandard)} />
									<Route path="/form-extended" component={waitFor(FormExtended)} />
									<Route path="/form-validation" component={waitFor(FormValidation)} />
									<Route path="/form-wizard" component={waitFor(FormWizard)} />
									<Route path="/form-upload" component={waitFor(FormUpload)} />
									<Route path="/form-cropper" component={waitFor(FormCropper)} />
									{/*Charts*/}
									<Route path="/chart-flot" component={waitFor(ChartFlot)} />
									<Route path="/chart-radial" component={waitFor(ChartRadial)} />
									<Route path="/chart-chartjs" component={waitFor(ChartChartJS)} />
									<Route path="/chart-morris" component={waitFor(ChartMorris)} />
									<Route path="/chart-chartist" component={waitFor(ChartChartist)} />
									{/*Table*/}
									<Route path="/table-standard" component={waitFor(TableStandard)} />
									<Route path="/table-extended" component={waitFor(TableExtended)} />
									<Route path="/table-datatable" component={waitFor(Datatable)} />
									<Route path="/table-datagrid" component={waitFor(DataGrid)} />
									{/*Maps*/}
									<Route path="/map-google" component={waitFor(MapsGoogle)} />
									<Route path="/map-vector" component={waitFor(MapsVector)} />
									{/*Extras*/}
									<Route path="/mailbox" component={waitFor(Mailbox)} />
									<Route path="/timeline" component={waitFor(Timeline)} />
									<Route path="/calendar" component={waitFor(Calendar)} />
									<Route path="/invoice" component={waitFor(Invoice)} />
									<Route path="/search" component={waitFor(Search)} />
									<Route path="/todo" component={waitFor(Todo)} />
									<Route path="/profile" component={waitFor(Profile)} />
									<Route path="/ecommerce-orders" component={waitFor(EcommerceOrder)} />
									<Route path="/ecommerce-order-view" component={waitFor(EcommerceOrderView)} />
									<Route path="/ecommerce-products" component={waitFor(EcommerceProduct)} />
									<Route path="/ecommerce-product-view" component={waitFor(EcommerceProductView)} />
									<Route path="/ecommerce-checkout" component={waitFor(EcommerceCheckout)} />
									<Route path="/blog-list" component={waitFor(BlogList)} />
									<Route path="/blog-post" component={waitFor(BlogPost)} />
									<Route path="/blog-articles" component={waitFor(BlogArticle)} />
									<Route path="/blog-article-view" component={waitFor(BlogArticleView)} />
									<Route path="/bug-tracker" component={waitFor(BugTracker)} />
									{/* <Route path="/contact-details" component={waitFor(ContactDetails)} /> */}
									<Route path="/contacts" component={waitFor(Contacts)} />
									<Route path="/faq" component={waitFor(Faq)} />
									<Route path="/file-manager" component={waitFor(FileManager)} />
									<Route path="/followers" component={waitFor(Followers)} />
									<Route path="/help-center" component={waitFor(HelpCenter)} />
									<Route path="/plans" component={waitFor(Plans)} />
									<Route path="/project-details" component={waitFor(ProjectDetails)} />
									<Route path="/projects" component={waitFor(Projects)} />
									<Route path="/settings" component={waitFor(Settings)} />
									<Route path="/social-board" component={waitFor(SocialBoard)} />
									<Route path="/team-viewer" component={waitFor(TeamViewer)} />
									<Route path="/vote-links" component={waitFor(VoteLinks)} />
									<Route path="/forum" component={waitFor(ForumHome)} />
									<Redirect to="/login" />
								</Switch>
							</Suspense>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</Base>
		);
	}
};

export default withRouter(Routes);
