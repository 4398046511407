import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import API from '../../api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import HeaderRun from './Header.run';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

class Header extends Component {
	componentDidMount() {
		this.LoadUserDetails();
		HeaderRun();
	}

	toggleUserblock = (e) => {
		e.preventDefault();
		this.props.actions.toggleSetting('showUserBlock');
	};

	toggleOffsidebar = (e) => {
		e.preventDefault();
		this.props.actions.toggleSetting('offsidebarOpen');
	};

	toggleCollapsed = (e) => {
		e.preventDefault();
		this.props.actions.toggleSetting('isCollapsed');
		this.resize();
	};

	toggleAside = (e) => {
		e.preventDefault();
		this.props.actions.toggleSetting('asideToggled');
	};

	resize() {
		// all IE friendly dispatchEvent
		var evt = document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
		// modern dispatchEvent way
		// window.dispatchEvent(new Event('resize'));
	}
	Logout() {
		if (localStorage.hasOwnProperty('tocken')) {
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`logout`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
					if (res.data.status === 200) {
						window.location.href = '/login';
					} else {
						ToastsStore.error(res.data.message, 5000);
						window.location.href = '/login';
					}
				})
				.catch((error) => {
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		} else {
			window.location.href = '/login';
		}
	}
	LoadUserDetails() {
		if (localStorage.hasOwnProperty('tocken')) {
			let tocken = localStorage.getItem('tocken');
			const AuthStr = 'Bearer '.concat(tocken);
			API.get(`me`, { headers: { Authorization: AuthStr } })
				.then((res) => {
					console.log(res.data);
				})
				.catch((error) => {
					// Error
					if (error.response) {
						if (error.response.status === 401) {
							localStorage.removeItem('tocken');
							window.location.href = '/login';
						}
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		} else {
			window.location.href = '/login';
		}
	}

	render() {
		return (
			<header className="topnavbar-wrapper">
				<ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} lightBackground />
				{/* START Top Navbar */}
				<nav className="navbar topnavbar">
					{/* START navbar header */}
					<div className="navbar-header">
						<a className="navbar-brand" href="/dashboardv1">
							<div className="brand-logo">
								<img className="img-fluid" src="img/logop.png" alt="App Logo" />
							</div>
							<div className="brand-logo-collapsed">
								<img className="img-fluid" src="img/logos.png" alt="App Logo" />
							</div>
						</a>
					</div>
					{/* END navbar header */}

					{/* START Left navbar */}
					<ul className="navbar-nav mr-auto flex-row">
						<li className="nav-item">
							{/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
							<a
								href=""
								className="nav-link d-none d-md-block d-lg-block d-xl-block"
								onClick={this.toggleCollapsed}
							>
								<em className="fas fa-bars" />
							</a>
							{/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
							<a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
								<em className="fas fa-bars" />
							</a>
						</li>
					</ul>
					{/* END Left navbar */}
					{/* START Right Navbar */}
					<ul className="navbar-nav flex-row">
						<UncontrolledDropdown nav inNavbar className="dropdown-list">
							<DropdownToggle nav className="dropdown-toggle-nocaret">
								<em className="icon-user" />
							</DropdownToggle>
							{/* START Dropdown menu */}
							<DropdownMenu right className="dropdown-menu-right animated flipInX">
								<DropdownItem>
									{/* START list group */}
									<ListGroup>
										<ListGroupItem action tag="a" href="" onClick={(e) => e.preventDefault()}>
											<div className="media">
												<div className="media-body">
													<Link to={'/changepassword'} className="m-0 text-muted text-sm">
														Change password
													</Link>
												</div>
											</div>
										</ListGroupItem>
										<ListGroupItem action tag="a" href="" onClick={(e) => e.preventDefault()}>
											<div className="media">
												<div className="media-body">
													<button
														onClick={(event) => this.Logout()}
														className="btn btn-danger mr-2"
													>
														Logout
													</button>
												</div>
											</div>
										</ListGroupItem>
									</ListGroup>
									{/* END list group */}
								</DropdownItem>
							</DropdownMenu>
							{/* END Dropdown menu */}
						</UncontrolledDropdown>
						{/* END Alert menu */}
						{/* START Offsidebar button */}
						{/* <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-notebook"></em>
                            </a>
                        </li> */}
						{/* END Offsidebar menu */}
					</ul>
					{/* END Right Navbar */}

					{/* START Search form */}
					<form className="navbar-form" role="search" action="search.html">
						<div className="form-group">
							<input className="form-control" type="text" placeholder="Type and hit enter ..." />
							<div className="fa fa-times navbar-form-close" data-search-dismiss="" />
						</div>
						<button className="d-none" type="submit">
							Submit
						</button>
					</form>
					{/* END Search form */}
				</nav>
				{/* END Top Navbar */}
			</header>
		);
	}
}

Header.propTypes = {
	actions: PropTypes.object,
	settings: PropTypes.object
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Header);
